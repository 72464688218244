import { Atom, Cmp, Mod } from ":mods";
import { createStore } from "solid-js/store";
import { For, createEffect, createMemo, createSignal, splitProps } from "solid-js";
import { AMaterialTaskContent, MaterialTaskDesignView } from "../../../../models";
import clone from "clone";
import { checkOptions } from "../methods";

//? response and request payloads
type RecievedPayload = AMaterialTaskContent<{
  image: any;
  description: string;
  title$?: string;
  title?: string;
  options: { id: number; value: string }[];
  option_answer?: number;
}>;
type RequestPayload = AMaterialTaskContent<{
  image?: boolean;
  description?: string;
  options?: number;
  title$?: string;
  title?: string;
  option_answer?: number;
}>;
type RequestPayloadSection = Partial<RequestPayload[0]>;
type RequestPayloadSectionContent = Partial<RequestPayloadSection["content"]>;
type RequestPayloadSectionContentItem = Partial<RequestPayloadSectionContent[0]>;
// -------------
//? narrative development
// -------------
export default function (props: MaterialTaskDesignView<RecievedPayload, RequestPayloadSectionContent>) {
  // setup vars before content
  let images = [];

  // track parent content
  const content = createMemo(() => {
    // on content updated or discarded
    images = [];
    return props.elements.data.content as RecievedPayload;
  });

  function onDataChange(idx: number, props: RequestPayloadSectionContentItem) {
    const items = content()[0].content as unknown as RequestPayloadSectionContent;
    items[idx] = { ...items[idx], ...props };
    //? this is where you process data before passing it to update
    for (const item of items) {
      // const new_item = { ...item, ...props } as RequestPayloadSectionContentItem;
      if (typeof item.image !== "boolean") {
        item.image = false;
      }
      checkOptions(item);
    }
    // pass data to update
    console.log("d :: ", items);
    onDataUpdate(items);
  }

  function onDataUpdate(data: RequestPayloadSectionContent) {
    const formData = new FormData();
    images.map((imagefile, i) => formData.append("images", imagefile, `tp03 image ${i + 1}`));
    formData.append("data", JSON.stringify(data));
    props.events?.onDataUpdate?.(formData, data);
  }

  return (
    <form>
      <For each={content()}>
        {(section, index) => {
          return (
            <>
              <section class="flex flex-wrap w-full pr-0.8rem pt-0.8rem gap-20px">
                <For each={section.content}>
                  {(content, idx) => {
                    return (
                      <div class={`flex flex-col gap-20px w-full h-full `}>
                        <div class="flex justify-between gap-10px w-full h-167px ">
                          <div class="w-45%">
                            <Atom.Tasks.Title
                              class="text-61px leading-43px  h-154px w-412px p-10px"
                              elements={{
                                title: content.title || content.title$,
                              }}
                              options={{
                                editable: false,
                              }}
                            />
                          </div>

                          <div class="w-full h-100%">
                            <Atom.Tasks.Description
                              class="w-full h-full"
                              elements={{
                                description: content.description,
                              }}
                              options={{
                                editable: props.options.editable,
                              }}
                              events={{
                                onChange: (e) => onDataChange(idx(), { description: e.target.value }),
                              }}
                            />
                          </div>
                        </div>
                        <div class="w-full mt-10px ">
                          <Atom.Tasks.Dropdown
                            class="w-full h-53px "
                            placeholder="Select Narrative Structure"
                            elements={{
                              options: content.options,
                              opt_ans: content.option_answer,
                            }}
                            options={{
                              editable: props.options.editable,
                            }}
                            events={{
                              onValueChange: (e) => onDataChange(idx(), { option_answer: e }),
                            }}
                          />
                        </div>
                        <div class="w-full h-300px my-10px">
                          <Atom.Tasks.Image
                            class="min-w-100% h-300px"
                            options={{
                              editable: props.options.editable,
                            }}
                            elements={{
                              images: images,
                              image: content.image,
                            }}
                            events={{
                              onImageChange: (e) => onDataChange(idx(), { image: e }),
                            }}
                          />
                        </div>
                      </div>
                    );
                  }}
                </For>
              </section>
            </>
          );
        }}
      </For>
    </form>
  );
}
